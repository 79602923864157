import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAtomsBlockquote, LazyAtomsBorder, LazyAtomsButton, LazyAtomsCaption, LazyAtomsDate, LazyAtomsDatedList, LazyAtomsEntry, LazyAtomsHamburger, LazyAtomsHeading, LazyAtomsIcon, LazyAtomsImage, LazyAtomsLargeText, LazyAtomsListComponent, LazyAtomsListItem, LazyAtomsList, LazyAtomsLoader, LazyAtomsNormal, LazyAtomsStats, LazyAtomsTags, LazyAtomsTimelineBorder, LazyBlocksSpotlight, LazyBlocksBackground, LazyBlocksCard, LazyBlocksContent, LazyBlocksEmbed, LazyBlocksEntry, LazyBlocksImages, LazyBlocksItem, LazyBlocksMap, LazyBlocksPagination, LazyBlocksSection, LazyBlocksTeam, LazyBlocksText, LazyBlocksTimeline, LazyBlocksVideo, LazyFormBody, LazyFormFieldAmount, LazyFormFieldCheck, LazyFormFieldGiftaid, LazyFormField, LazyFormFieldOptions, LazyFormFieldStripe, LazyPageHeader, LazySiteTooltip, LazySiteFooter, LazySiteHeader, LazySiteModal, LazySiteNav, LazySvgoBorder, LazySvgoDiamond, LazySvgoGiftaid, LazySvgoLogo, LazySvgoLogomark, LazySvgoLogostar, LazySvgoPlay, LazyCConcision, LazyCAtomsBlockquote, LazyCAtomsBurger, LazyCAtomsButton, LazyCAtomsCaption, LazyCAtomsColorModeToggle, LazyCAtomsDate, LazyCAtomsEmbed, LazyCAtomsHeading, LazyCAtomsIcon, LazyCAtomsImage, LazyCAtomsLargeText, LazyCAtomsLink, LazyCAtomsLoader, LazyCAtomsNormal, LazyCAtomsPill, LazyCAtomsSmallText, LazyCAtomsStatistic, LazyCAtomsTabs, LazyCAtomsVideo, LazyCBlocksAccordion, LazyCBlocksCard, LazyCBlocksContent, LazyCBlocksImages, LazyCBlocksList, LazyCBlocksMosaic, LazyCBlocksRow, LazyCBlocksTestimonial, LazyCBlocksTestimonials, LazyCBlocksText, LazyCForm, LazyCFormCheckbox, LazyCFormField, LazyCFormInput, LazyCFormOption, LazyCFormTextarea, LazyCLayoutAccordions, LazyCLayoutCardGrid, LazyCLayoutContainer, LazyCLayoutFullWidth, LazyCOverlayLightbox, LazyCOverlayModal, LazyCSiteScrollDetector, LazyCSiteSection, LazyUnoIcon, LazySanityContent, LazySanityFile, LazySanityImage } from '#components'
const lazyGlobalComponents = [
  ["AtomsBlockquote", LazyAtomsBlockquote],
["AtomsBorder", LazyAtomsBorder],
["AtomsButton", LazyAtomsButton],
["AtomsCaption", LazyAtomsCaption],
["AtomsDate", LazyAtomsDate],
["AtomsDatedList", LazyAtomsDatedList],
["AtomsEntry", LazyAtomsEntry],
["AtomsHamburger", LazyAtomsHamburger],
["AtomsHeading", LazyAtomsHeading],
["AtomsIcon", LazyAtomsIcon],
["AtomsImage", LazyAtomsImage],
["AtomsLargeText", LazyAtomsLargeText],
["AtomsListComponent", LazyAtomsListComponent],
["AtomsListItem", LazyAtomsListItem],
["AtomsList", LazyAtomsList],
["AtomsLoader", LazyAtomsLoader],
["AtomsNormal", LazyAtomsNormal],
["AtomsStats", LazyAtomsStats],
["AtomsTags", LazyAtomsTags],
["AtomsTimelineBorder", LazyAtomsTimelineBorder],
["BlocksSpotlight", LazyBlocksSpotlight],
["BlocksBackground", LazyBlocksBackground],
["BlocksCard", LazyBlocksCard],
["BlocksContent", LazyBlocksContent],
["BlocksEmbed", LazyBlocksEmbed],
["BlocksEntry", LazyBlocksEntry],
["BlocksImages", LazyBlocksImages],
["BlocksItem", LazyBlocksItem],
["BlocksMap", LazyBlocksMap],
["BlocksPagination", LazyBlocksPagination],
["BlocksSection", LazyBlocksSection],
["BlocksTeam", LazyBlocksTeam],
["BlocksText", LazyBlocksText],
["BlocksTimeline", LazyBlocksTimeline],
["BlocksVideo", LazyBlocksVideo],
["FormBody", LazyFormBody],
["FormFieldAmount", LazyFormFieldAmount],
["FormFieldCheck", LazyFormFieldCheck],
["FormFieldGiftaid", LazyFormFieldGiftaid],
["FormField", LazyFormField],
["FormFieldOptions", LazyFormFieldOptions],
["FormFieldStripe", LazyFormFieldStripe],
["PageHeader", LazyPageHeader],
["SiteTooltip", LazySiteTooltip],
["SiteFooter", LazySiteFooter],
["SiteHeader", LazySiteHeader],
["SiteModal", LazySiteModal],
["SiteNav", LazySiteNav],
["SvgoBorder", LazySvgoBorder],
["SvgoDiamond", LazySvgoDiamond],
["SvgoGiftaid", LazySvgoGiftaid],
["SvgoLogo", LazySvgoLogo],
["SvgoLogomark", LazySvgoLogomark],
["SvgoLogostar", LazySvgoLogostar],
["SvgoPlay", LazySvgoPlay],
["CConcision", LazyCConcision],
["CAtomsBlockquote", LazyCAtomsBlockquote],
["CAtomsBurger", LazyCAtomsBurger],
["CAtomsButton", LazyCAtomsButton],
["CAtomsCaption", LazyCAtomsCaption],
["CAtomsColorModeToggle", LazyCAtomsColorModeToggle],
["CAtomsDate", LazyCAtomsDate],
["CAtomsEmbed", LazyCAtomsEmbed],
["CAtomsHeading", LazyCAtomsHeading],
["CAtomsIcon", LazyCAtomsIcon],
["CAtomsImage", LazyCAtomsImage],
["CAtomsLargeText", LazyCAtomsLargeText],
["CAtomsLink", LazyCAtomsLink],
["CAtomsLoader", LazyCAtomsLoader],
["CAtomsNormal", LazyCAtomsNormal],
["CAtomsPill", LazyCAtomsPill],
["CAtomsSmallText", LazyCAtomsSmallText],
["CAtomsStatistic", LazyCAtomsStatistic],
["CAtomsTabs", LazyCAtomsTabs],
["CAtomsVideo", LazyCAtomsVideo],
["CBlocksAccordion", LazyCBlocksAccordion],
["CBlocksCard", LazyCBlocksCard],
["CBlocksContent", LazyCBlocksContent],
["CBlocksImages", LazyCBlocksImages],
["CBlocksList", LazyCBlocksList],
["CBlocksMosaic", LazyCBlocksMosaic],
["CBlocksRow", LazyCBlocksRow],
["CBlocksTestimonial", LazyCBlocksTestimonial],
["CBlocksTestimonials", LazyCBlocksTestimonials],
["CBlocksText", LazyCBlocksText],
["CForm", LazyCForm],
["CFormCheckbox", LazyCFormCheckbox],
["CFormField", LazyCFormField],
["CFormInput", LazyCFormInput],
["CFormOption", LazyCFormOption],
["CFormTextarea", LazyCFormTextarea],
["CLayoutAccordions", LazyCLayoutAccordions],
["CLayoutCardGrid", LazyCLayoutCardGrid],
["CLayoutContainer", LazyCLayoutContainer],
["CLayoutFullWidth", LazyCLayoutFullWidth],
["COverlayLightbox", LazyCOverlayLightbox],
["COverlayModal", LazyCOverlayModal],
["CSiteScrollDetector", LazyCSiteScrollDetector],
["CSiteSection", LazyCSiteSection],
["UnoIcon", LazyUnoIcon],
["SanityContent", LazySanityContent],
["SanityFile", LazySanityFile],
["SanityImage", LazySanityImage],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
