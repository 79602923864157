import {
  internals,
  externals,
  button,
  simpleText,
  image,
  markDefs,
} from '~~/global/groq'

const images = groq`
images {
  items[] ${image}
}`

const card = groq`
  "slug": slug.current,
  title,
  "type": _type,
  "text": description,
  "image": images[0]${image},
  datePublished,
  "to": { "id": _type + '_' + slug.current }
`

const pageCards = groq`{
  "children": *[_type == 'page' && (parent._ref == ^._id || _id == ^._id || (_id == ^.parent._ref && slug.current != 'home')) && !('excludeFromNav' in options)] | score(_id == ^._id, boost(_id == ^.parent._ref, 10)) {
    ${card},
    "type": 'page',
    "parent": select(_id == ^._id => 'parent', _id == ^.parent._ref => 'grandparent', false)
  },
  parent->slug.current != 'home' => {
    "siblings": *[_type == 'page' && (parent._ref == ^.parent._ref || _id == ^.parent._ref) && !('excludeFromNav' in options)] | score(_id == ^.parent._ref) {
      ${card},
      "type": 'page',
      "parent": select(_id == ^.parent._ref => 'parent', false)
    }
  }
}`

const articleCards = groq`{
  "siblings": *[_type == ^._type && _id != ^._id && dateTime(now()) > dateTime(datePublished)] | order(datePublished desc) [0...3]{
    ${card}
  },
}`

const body = (name) => groq`{
  ...,
  ${markDefs},
  style == "h3" || style == "h4" || style == "h5" || style == "h6"  => {
    "element": style,
    "style": "heading"
  },
  _type == 'background' => {
    ...,
    "image": ${image},
  },
  _type == "button" => ${button('"contentButton": "button"')},
  _type == "textBlock" => {
    ...,
    content[] {
      ...,
      ${markDefs},
      _type == "button" => ${button('"contentButton": "button"')},
    },
  },
  _type == "form" => {
    ...(id->{
      "id": id.current,
      "type": id.current,
      "sections": sections{
        title,
        fields{
          ...,
          "_key": null,
          "_type": null,
          "id": null,
          "name": id.current,
          options{
            "value": id.current,
            label
          }[]
        }[]
      }[]
    })
  },
  _type == "image" => {
    ...,
    "image": ${image}
  },
  _type == "images" => {
    ...,
    items[] ${image}
  },
  _type == 'video' => {
    ...(video->{
      "image": image${image},
      "video": video.asset->
    })
  },
  _type == "content" => {
    ...,
    ${markDefs},
    type,
    max,
    offset,
    "tags": *[_type == ^.type].tags,
  },
  _type == 'personnel' => {
    "team": *[_type == 'personnel' && type == ^.type && !(_id in path("drafts.**"))] {
      name,
      role,
      image,
      biography[] ${simpleText},
      "weighting": select(defined(weighting) => weighting, 0),
      image ${image}
    } | order(string::split(name, " ")[-1], asc) | order(role asc) | order(weighting desc)
  },
  _type == 'timeline' => {
    chapters[] {
      ...,
      content[] {
        ...,
        ${markDefs},
        style == "h3" || style == "h4" || style == "h5" || style == "h6"  => {
          "element": style,
          "style": "heading"
        },
        _type == "image" => {
          ...,
          "image": ${image}
        },
        _type == "images" => {
          ...,
          items[] ${image}
        },
        _type == "button" => ${button('"contentButton": "button"')},
      }
    }
  },
  _type in ["card", "spotlight"] => {
    "layout": ^.^.layout,
    "name": "${name}",
    type == 'page' => {
      ${internals(false)},
      ${externals}
    },
    type == 'article' => {
      !defined(internal) => {
        "article": *[_type == 'article' && dateTime(now()) > dateTime(datePublished)] | order(datePublished desc)[0] {
          "to": { "id": 'article_' + slug.current },
          title,
          quote,
          "text": description,
          "image": images[0]${image},
        }
      },
      defined(internal) => {
        ${internals(false)},
        ${externals},
      }
    },
    ...(internal->{
      ^.type == 'video' => {
        "video": video.asset->
      },
      defined(image) => {
        "image": image${image}
      },
      defined(images) => {
        "image": images[0]${image}
      },
      title,
      quote,
      description,
      _type == "card" => {button}
    }),
    defined(image) => {
      "image": image${image}
    },
    defined(title) => {title},
    defined(quote) => {quote},
    defined(text) => {text},
    defined(button) => {button},
    plain,
  }
}`

const section = (name: string) => groq`
  count(${name}.items) > 0 => {
    ${name} {
      _key,
      "name": "${name}",
      items[] ${body(name)}
    }
  }`

const cards = groq`
  _type == 'page' && slug.current != 'home' => ${pageCards},
  _type != 'page' => ${articleCards},
`

const page = groq`*[_type in ["page", "article", "story"] && (_id == $slug || slug.current == $slug)][0] {
  "slug": slug.current,
  title,
  "type": _type,
  "image": images[0]${image},
  "heading": {
    "heading": select(defined(heading) => heading, title),
    "slug": slug.current,
    _type == 'page' && slug.current != 'home' => {
      ...(parent->{
        defined(slug.current) && slug.current != 'home' => {
          "parent": {
            "to": { "id": 'page_' + slug.current },
            "text": title,
          },
        }
      })
    },
    _type == 'article' => {
      datePublished,
      "parent": {
        "slug": 'news',
        "to": { "id": 'page_news' },
        "text": 'News'
      },
    },
    _type == "story" => {
      "parent": {
        "slug": 'stories',
        "to": { "id": 'page_stories' },
        "text": "Stories"
      },
    },
    'altLayout' in options => {
      "theme": {
        "image": images[0]${image},
        "background": "black",
        ...(images[0].asset->metadata.palette.darkMuted{
          "color": title,
          "accent": foreground,
        })
      },
      "supermask": false
    },
    introduction,
    !defined(options) || !('altLayout' in options) => {
      images[]${image},
      background {
        "image": ${image}
      },
    }
  },
  slug.current != 'home' => { ${cards} },
  body[] {
    _key,
    _type,
    "layout": select(defined(layout) => layout, layoutForAlt),
    background,
    level,
    align,
    background {
      ...,
      image${image},
    },
    ${section('first')},
    ${section('sidebar')},
    ${section('second')},
    ${section('third')},
    "options": {
      "hideMarginBottom": 'hideMarginBottom' in options,
    }
  },
  "options": {
    "excludeFromNav": 'excludeFromNav' in options,
    "hidePageCards": 'hidePageCards' in options,
    "altLayout": 'altLayout' in options,
  }
}`

export default () => ({
  button: (...internalsOptions) => groq`
    ${internals(...internalsOptions)},
    ${externals},
    defined(title) => {"text": title}
  `,
  image,
  images,
  section,
  page,
})