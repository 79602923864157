import { useState } from "nuxt/app"
import { computed, reactive } from "vue"

const useLightbox = () => {
  const lightbox = reactive({
    value: useState<boolean | CLightboxItem>('lightbox-value', () => false),
    active: computed(() => !!lightbox.value),
    group: useState<CLightboxItem[]>('group', () => []),
    zoom: useState<boolean>('zoom', () => false),
    set: (value?: CLightboxItem, group?: CLightboxItem[]) => {
      if (!value) return lightbox.clear()

      lightbox.zoom = false
      lightbox.value = value
      if (group && group.length > 1) lightbox.group = group
    },
    clear: () => {
      lightbox.value = false
      lightbox.zoom = false
      lightbox.group = []
    }
  })

  return {
    lightbox
  }
}

export default useLightbox