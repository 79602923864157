import type { AdvancedRoute, Glossary } from '~~/types'
import { simpleText } from '~~/global/groq'
import routesQuery from '~~/global/routes'

export default defineNuxtPlugin(async ({ $sanity }) => {
  const content = {
    article: [] as AdvancedRoute[],
    story: [] as AdvancedRoute[],
    entry: [] as Glossary[],
  }

  const parseGlossary = (data: Glossary[]) => {
    const entries: { [key: string]: Glossary } = {}
    const terms: string[] = []

    data.forEach((entry) =>
      entry.terms.forEach((term) => {
        entries[term] = entry
        terms.push(term)
      })
    )

    content.entry = data

    return {
      entries,
      terms: terms.sort().reverse(),
    }
  }

  const routes = await $sanity
    .fetch(routesQuery(true))
    .then((res: AdvancedRoute[]) =>
      res.reduce((map, route) => {
        if ('type' in route) content[route.type].push(route)

        return { ...map, [route.id]: route.path }
      }, {})
    )

  const glossary = await $sanity
    .fetch(
      groq`*[_type == 'entry'] {
        title,
        terms,
        "definition": pt::text(definition),
        "definitionFull": definition[] ${simpleText},
      } | order(title asc)`
    )
    .then((res) => parseGlossary(res))

  return {
    provide: {
      routes,
      glossary,
      content,
      getSlug: (page) =>
        Array.isArray(page)
          ? page.filter((f) => !!f).pop()
          : !page
          ? 'home'
          : page,
    },
  }
})
