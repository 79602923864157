import { useRoute, useState } from "nuxt/app"
import { computed, reactive } from "vue"
import { PositionResult } from 'vue-router/types/router'

const useCzn = () => {
  const onHome = computed(() => useRoute().path === '/')

  const affix = useState<Record<string, boolean>>('affix', () => ({ top: false }))

  const scrolled = useState('scrolled', () => false)

  const mounted = useState('mounted', () => false)

  const sectionActive = useState('section-active', () => '')

  const accordion = reactive({
    active: useState<string | number>('accordion-active', () => ''),
    set: (id: string | number) =>
      accordion.active === id ? (accordion.active = '') : (accordion.active = id),
    setWithoutToggle: (id: string | number) => (accordion.active = id),
    isActive: (id: string | number) => accordion.active === id
  })

  const menu = reactive({
    active: useState('menu-active', () => false),
    set: (value?: boolean) => {
      value !== undefined ? menu.active = value : menu.active = !menu.active
    },
    close: () => (menu.active = false),
    toggle: () => (menu.active = !menu.active),
    title: computed(() => menu.active ? 'Close menu' : 'Open menu'),
    isActive: () => menu.active
  })

  const siteLoading = reactive({
    active: useState('site-loading-active', () => true),
    images: useState<string[]>('site-loading-images', () => ([])),
    imagesLoaded: useState<string[]>('site-loading-images-active', () => ([])),
    loaded: useState('site-loading-loaded', () => 0),
    loadImage: (image: string) => {
      siteLoading.imagesLoaded.push(image)
      siteLoading.loaded++
      if (process.env.NODE_ENV !== 'production')
        // eslint-disable-next-line
        console.log(
          'Loaded ' +
            siteLoading.loaded +
            '/' +
            (1 + siteLoading.images.length) +
            ': ' +
            image
        )
      if (siteLoading.loaded >= 1 + siteLoading.images.length) siteLoading.active = false
    }
  })

  async function scroll(
    scrollTo: string | number
  ): Promise<PositionResult> {
    const findEl = async (hash: string, x = 0): Promise<HTMLElement> => {
      return (
        (document.querySelector(hash) as HTMLElement) ||
        new Promise((resolve) => {
          if (x > 50) {
            return resolve
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1))
          }, 100)
        })
      )
    }
    const root = document.documentElement || window
    let y = 0
    if (typeof scrollTo === 'number') {
      y = scrollTo
    } else if (typeof scrollTo === 'string') {
      const el = await findEl(`#${scrollTo}`)
      if (!el) return
      y = el.offsetTop - document.body.scrollTop
    }
  
    if ('scrollBehavior' in document.documentElement.style) {
      return root.scrollTo({
        top: y,
        behavior: 'smooth',
      })
    } else {
      return root.scrollTo(0, y)
    }
  }

  return {
    onHome,
    mounted,
    affix,
    scrolled,
    sectionActive,
    accordion,
    menu,
    siteLoading,
    scroll
  }
}

export default useCzn