import revive_payload_client_IsdRA8g7Lh from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_wskp2aboliiuttnhawz4nxpelq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_t0gO32u2rF from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_wskp2aboliiuttnhawz4nxpelq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NBuSrls2bn from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_wskp2aboliiuttnhawz4nxpelq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1htsGa2urB from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_vite@6.1.0+vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_0oB9GG3B3c from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_wskp2aboliiuttnhawz4nxpelq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_07NSY40rhE from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_wskp2aboliiuttnhawz4nxpelq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HIdplth9cc from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_wskp2aboliiuttnhawz4nxpelq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_DhCJLV0Pj4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_wskp2aboliiuttnhawz4nxpelq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_EtSRkfYJwR from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_wskp2aboliiuttnhawz4nxpelq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_dSj01tlbxD from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_vite@6.1.0+vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_E1GeRRXfEk from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_vite@6.1.0+vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Zb3CzR5oE2 from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_vite@6.1.0+vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import global_helper_os3Twr0sMC from "/vercel/path0/node_modules/.pnpm/@nuxtjs+sanity@1.13.3_cesiitr7do6f2vfyir6mgt4kmi/node_modules/@nuxtjs/sanity/dist/runtime/plugins/global-helper.js";
import unocss_RAHspWIKy3 from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import icons_GVflboIuoj from "/vercel/path0/app/plugins/icons.ts";
import sanity_TNOdue8ztg from "/vercel/path0/app/plugins/sanity.ts";
import defaults_Stparg2xGo from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_vite@6.1.0+vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_IsdRA8g7Lh,
  unhead_t0gO32u2rF,
  router_NBuSrls2bn,
  _0_siteConfig_1htsGa2urB,
  payload_client_0oB9GG3B3c,
  navigation_repaint_client_07NSY40rhE,
  check_outdated_build_client_HIdplth9cc,
  chunk_reload_client_DhCJLV0Pj4,
  components_plugin_zlvi6dcIsi,
  prefetch_client_EtSRkfYJwR,
  siteConfig_dSj01tlbxD,
  inferSeoMetaPlugin_E1GeRRXfEk,
  titles_Zb3CzR5oE2,
  global_helper_os3Twr0sMC,
  unocss_RAHspWIKy3,
  icons_GVflboIuoj,
  sanity_TNOdue8ztg,
  defaults_Stparg2xGo
]