const domain = 'drgrahamshomes.co.uk'

export const site: Site = {
  title: 'Dr Graham’s Homes',
  description: 'Meeting the need, transforming lives: DGHUK is a Scottish charity that supports the work of the Dr Graham’s Homes school in Kalimpong, India - as they care for children in need.',
  url: 'https://' + domain,
  domain,
}

export const isProd = process.env.NODE_ENV !== 'development'

export const layouts = {
  'col-1': ['first'],
  'col-2-image': ['first'],
  'col-2': ['first', 'sidebar'],
  'col-2-equal': ['first', 'second'],
  'col-3-equal': ['first', 'second', 'third'],
} as const