import type { ChildGroq, ItemGroq, PageGroq, Page, Column, Section, Item, Card, Member, SectionGroq } from '~~/types'
import { layouts, site } from '~~/global'

const bodyColumns = ['first', 'second', 'third', 'sidebar'] as const
const bodyItems = ['block', 'button', 'heading', 'background', 'form', 'images']

const mask =
  'https://cdn.sanity.io/images/2oiwztas/production/4054d5931b9b262e69b743712bdb9c621a37a887-16355x600.png?auto=format'

export default () => {
  const { $glossary } = useNuxtApp()
  const { getImageUrl, preloadImage } = useSanityImages()
  
  const theme = ref('')

  const addArticleButton = (type: string): Section | undefined => {
    if (!['article', 'story'].includes(type)) return
    const types = type === 'story' ? 'Stories' : 'News'
    return {
      _key: 'articleButton',
      layout: 'col-1',
      options: {},
      theme: {},
      columns: [
        {
          name: 'first',
          stretch: false,
          delay: 0,
          items: [
            {
              key: '0',
              delay: 0,
              style: 'margin-top: 2rem',
              _type: 'button',
              text: `Back to ${types}`,
              contentButton: 'button',
              to: { id: `page_${types.toLowerCase()}` },
            },
          ],
        },
      ],
    }
  }

  const generatePageBodyCards = (
    slug: string,
    relatedPages: Card[],
    hidePageCards?: boolean
  ): Section | undefined => {
    let items = relatedPages?.filter((page) => page.slug !== slug)

    if (items?.length > 3) items = items?.filter((page) => !page.parent)

    if (hidePageCards || !items?.length) return

    const layout =
      items.length === 1
        ? `col-1`
        : items.length === 2
        ? `col-2-equal`
        : 'col-3-equal'

    const bodyCards: Section = {
      _key: 'bodyCards0',
      layout,
      options: {},
      theme: {},
      columns: ['first', 'second', 'third']
        .map(
          (col, index) =>
            items[index] && {
              name: col,
              delay: index * 0.5,
              items: [
                {
                  _key: index,
                  delay: index * 0.5,
                  _type: 'card',
                  ...items[index],
                },
              ],
              stretch: true,
            }
        )
        .filter((col) => !!col),
    }

    if (!bodyCards.columns.length) {
      console.log(bodyCards)
      return
    }

    return bodyCards
  }

  const termsUsed: string[] = []

  const parseItemChildren = (children: ChildGroq[]) => {
    const newChildren: ChildGroq[] = []

    children?.slice()?.forEach((child) => {
      if (child.marks?.length && !child.marks.includes('strong'))
        return newChildren.push(child)
      const matches: { term: string; index: number }[] = []
      $glossary.terms.forEach((term) => {
        if (termsUsed.includes(term)) return
        const index = child.text.indexOf(term)
        if (index === -1) return
        matches.push({ term, index })
        // termsUnused = termsUnused.filter(
        //   (t) => !$glossary.entries[term]?.terms?.includes(t)
        // )
        termsUsed.push(...($glossary.entries[term]?.terms || [term]))
      })

      if (!matches?.length) return newChildren.push(child)


      const finalChild: ChildGroq[] = []

      const sortedMatches = matches.sort((a, b) => a.index - b.index)

      sortedMatches.forEach(({ term }) => {
        const useText = finalChild.pop()?.text || child.text
        const [first, ...rest] = useText.split(term)

        finalChild.push(
          ...[
            {
              ...child,
              text: first,
            },
            {
              ...child,
              ...$glossary.entries[term],
              _type: 'entry',
              text: term,
            },
            {
              ...child,
              text: rest.join(term),
            },
          ]
        )
      })

      return newChildren.push(...finalChild)
    })

    return newChildren
  }

  const parseItem = (item: ItemGroq) => {
    if (item._type !== 'block' || item.element || !item.children) return item

    return {
      ...item,
      children: parseItemChildren(item.children),
    }
  }

  const parseItems = (item: ItemGroq) => {
    const subs = ['personnel', 'timeline']
    if (subs.includes(item._type)) {
      // console.log(item)
      let sub = ''
      let subItem = ''
      switch (item._type) {
        case 'personnel':
          sub = 'team'
          subItem = 'biography'
          break
        case 'timeline':
          sub = 'chapters'
          subItem = 'content'
          break
      }
      return {
        ...item,
        [sub]: item[sub]?.map((child: Member, index) => ({
          ...child,
          [subItem]: child[subItem]?.map((subSub, itemIndex) => ({
            ...parseItem(subSub),
            delay: index * 0.25 + itemIndex * 0.25,
          })),
        })),
      }
    }

    return parseItem(item)
  }


  let ind = 0
  let rev = true
  let cards = false

  const parsePageSection = (pageTheme?: string) => (section: SectionGroq, thisIndex: number): Section => {
    const columns = bodyColumns
      .filter(
        (col) =>
          section[col]?.items?.length &&
          // @ts-ignore
          layouts[section.layout]?.includes(col)
      )
      .map(
        (col, index): Column => ({
          name: col,
          // background: section[col].background,
          // ...section[col],
          delay: index * 0.5,
          items:
            section[col]?.items.map(
              (item, itemIndex): Item => ({
                ...parseItems(item),
                caption:
                  (item.style === 'blockquote' &&
                    section[col]?.items[itemIndex + 1]?.style ===
                      'caption') ||
                  null,
                delay: index * 0.5 + itemIndex * 0.3,
              })
            ) || [],
          stretch: !section[col]?.items?.every((item) =>
            bodyItems.includes(item._type)
          ),
        })
      )
    const sectionCards = columns.every(
      (col) => col?.items[0]?._type === 'card'
    )
    const sectionSdImage = section?.sidebar?.items && section.sidebar.items[0]?._type === 'images'
    let reverse = false
    if (section.layout === 'col-2') {
      if (ind === thisIndex && (sectionSdImage || (cards && sectionCards))) {
        reverse = rev
        rev = !reverse
      }
      ind = thisIndex + 1
    } else rev = true

    cards = sectionCards

    const image = section.background?.image

    const theme =
      pageTheme === 'alt' && section.background
        ? {
            // layout: section.background.layout,
            background: image?.metadata.palette.darkMuted.background, // section.background.color ||
            image,
            color: image?.metadata.palette.darkMuted.title,
            accent: image?.metadata.palette.darkMuted.foreground,
          }
        : {}

    return {
      _key: section._key,
      layout: section.layout,
      options: section.options,
      level: section.level,
      align: section.align,
      theme,
      map:
        section.layout === 'col-1' &&
        columns[0]?.items[0]?._type === 'map' &&
        columns[0]?.items.length === 1,
      columns,
      reverse,
      sectionCards,
      sectionSdImage,
    }
  }

  const generatePageHead = ({ title, description, image, heading, options}: Partial<PageGroq>) => ({
    title: `${title} ~ ${site.title}`,
    description,
    meta: [
      {
        hid: 'og:title',
        property: 'og:title',
        content: `${title} ~ ${site.title}`,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
      ...(image
        ? [
            {
              hid: 'og:image',
              property: 'og:image',
              content: getImageUrl(image, {
                width: 1200,
                quality: 80,
              }),
            },
          ]
        : []),
    ],
    link: [
      ...(heading?.background?.image && !options?.altLayout
        ? [
            {
              rel: 'preload',
              as: 'image',
              href: mask,
              media: '(min-width: 1024px)',
              crossOrigin: 'anonymous',
            } as any,
          ]
        : []),
      ...(heading?.background?.image
        ? [preloadImage(heading.background.image)]
        : []),
      ...(image ? [preloadImage(image)] : []),
    ],
  })

  const transformPage = (page: PageGroq): Page => {
    const pageTheme = page.options.altLayout ? 'alt' : undefined

    const body = page.body.map(parsePageSection(pageTheme))

    let relatedPages: Card[] = []

    if (page.children && page.children.filter((child) => !child.parent)?.length)
      relatedPages = page.children
    else if (page.siblings)
      relatedPages = page.siblings

    delete page.children
    delete page.siblings

    theme.value = page.options.altLayout ? 'alt' : ''

    const articleButton = addArticleButton(page.type)

    if (articleButton) {
      const bodyItem = body[body.length - 1]

      if (bodyItem)
        bodyItem.options.hideMarginBottom = true

      body.push(articleButton)
    }

    const bodyCards = generatePageBodyCards(page.slug, relatedPages, page.options.hidePageCards)

    if (bodyCards) body.push(bodyCards)

    const nav = page.type !== 'page' && page.heading.parent
      ? [page.heading.parent]
      : relatedPages?.map((item) => ({
          ...item,
          text: item?.title, // item?.text || 
          title: undefined,
        }))

    const pageResult = reactive({
      ...page,
      heading: { ...page.heading, nav },
      theme: pageTheme,
      body,
      head: generatePageHead(page)
    })
    return pageResult
  }

  return {
    transform: transformPage,
    theme,
  }
}
