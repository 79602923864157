// "paper-airplane": {
//   "body": "<path fill=\"currentColor\" d=\"M10.894 2.553a1 1 0 0 0-1.788 0l-7 14a1 1 0 0 0 1.169 1.409l5-1.429A1 1 0 0 0 9 15.571V11a1 1 0 1 1 2 0v4.571a1 1 0 0 0 .725.962l5 1.428a1 1 0 0 0 1.17-1.408l-7-14Z\"/>"
// },
// "phone": {
//   "body": "<path fill=\"currentColor\" d=\"M2 3a1 1 0 0 1 1-1h2.153a1 1 0 0 1 .986.836l.74 4.435a1 1 0 0 1-.54 1.06l-1.548.773a11.037 11.037 0 0 0 6.105 6.105l.774-1.548a1 1 0 0 1 1.059-.54l4.435.74a1 1 0 0 1 .836.986V17a1 1 0 0 1-1 1h-2C7.82 18 2 12.18 2 5V3Z\"/>"
// },
// "x-mark": {
//   "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.28 3.22a.75.75 0 0 0-1.06 1.06L8.94 10l-5.72 5.72a.75.75 0 1 0 1.06 1.06L10 11.06l5.72 5.72a.75.75 0 1 0 1.06-1.06L11.06 10l5.72-5.72a.75.75 0 0 0-1.06-1.06L10 8.94L4.28 3.22Z\" clip-rule=\"evenodd\"/>",
//   "hidden": true
// },
// "arrow-long-right": {
//   "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M2 10a.75.75 0 0 1 .75-.75h12.59l-2.1-1.95a.75.75 0 1 1 1.02-1.1l3.5 3.25a.75.75 0 0 1 0 1.1l-3.5 3.25a.75.75 0 1 1-1.02-1.1l2.1-1.95H2.75A.75.75 0 0 1 2 10Z\" clip-rule=\"evenodd\"/>",
//   "hidden": true
// },
// "arrow-left": {
//   "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M9.707 16.707a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 1.414L5.414 9H17a1 1 0 1 1 0 2H5.414l4.293 4.293a1 1 0 0 1 0 1.414Z\" clip-rule=\"evenodd\"/>"
// },
// "arrow-right": {
//   "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M10.293 3.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414L14.586 11H3a1 1 0 1 1 0-2h11.586l-4.293-4.293a1 1 0 0 1 0-1.414Z\" clip-rule=\"evenodd\"/>"
// },
// "chevron-left": {
//   "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.707 5.293a1 1 0 0 1 0 1.414L9.414 10l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0Z\" clip-rule=\"evenodd\"/>"
// },
// "chevron-right": {
//   "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.293 14.707a1 1 0 0 1 0-1.414L10.586 10L7.293 6.707a1 1 0 0 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0Z\" clip-rule=\"evenodd\"/>"
// },

// import fa from '@iconify-json/fa/icons.json'
// import hi from '@iconify-json/heroicons-solid/icons.json'

// type Libraries = 'hi' // | 'fa'

// type Library = {
//   icons: {
//     [key: string]: string
//   }
//   aliases: {
//     [key: string]: {
//       parent: string
//       [key: string]: string | boolean
//     }
//   }
// }

type Aliases = Record<string, string>

// const mapIcons = (
//   icons: { [key: string]: any },
//   library: string,
//   filter?: string[]
// ) =>
//   Object.keys(icons)
//     .filter((i) => !filter || filter.some((match) => i === match))
//     .map((i) => `i-${library}-${i}`)

// const socialIconsFilter = ['facebook', 'instagram', 'linkedin', 'twitter']

// const libraries = {
//   // fa,
//   hi: {
//     icons: hi.icons,
//     aliases: hi.aliases,
//     label: 'heroicons-solid',
//   },
// }
// const hi = {
//   icons: {},
//   aliases: {
//     'exclaimation-circle': { parent: 'exclamation-circle' },
//     'exclaimation-triangle': { parent: 'exclamation-triangle' }
//   }
// }

// const heroicons = [
//   'paper-airplane',
//   'phone',
//   'x-mark',
//   'arrow-long-right',
//   'arrow-left',
//   'arrow-right',
//   'chevron-left',
//   'chevron-right',
// ]
// const safelist = mapIcons(
//   hi.icons,
//   'heroicons-solid',
//   heroicons
//   // socialIconsFilter
// )

export const safelist = [
  'i-heroicons-solid-pencil-square',
  'i-heroicons-solid-arrow-left',
  'i-heroicons-solid-arrow-long-right',
  'i-heroicons-solid-arrow-right',
  'i-heroicons-solid-chevron-left',
  'i-heroicons-solid-chevron-right',
  'i-heroicons-solid-paper-airplane',
  'i-heroicons-solid-phone',
  'i-heroicons-solid-x-mark',
  'i-fa-instagram',
]
export const aliases: Aliases = {
  'exclaimation-circle': 'exclamation-circle',
  'exclaimation-triangle': 'exclamation-triangle',
}

// const parseAliases = (
//   aliases: Library['aliases'],
//   filter?: string[]
// ): Aliases =>
//   Object.keys(aliases)
//     .filter((i) => !filter || filter.some((match) => i.includes(match)))
//     .reduce((acc, key) => {
//       acc[key] = aliases[key].parent
//       return acc
//     }, {})

// export const aliases: Aliases = parseAliases(hi.aliases)
