<template>
  <BlocksSection
    element="header"
    class="site-header"
    :class="{
      affix: preview || affix.top,
      preview,
      'theme-alt': theme === 'alt' && !affix.top && !menu.active,
    }"
    layout="col-3-equal"
  >
    <AtomsList v-if="!preview && items" :items="items" custom-class="site-header-nav" />
    <AtomsButton
      :to="{ id: 'page_home' }"
      plain
      aria-label="Dr Grahams Homes homepage"
      class="site-header-logo"
      @click="menu.close()"
      ><Logo  :class="{ minimal: preview || affix.top }"
    /></AtomsButton>
    <div v-if="!preview" class="site-header-right">
      <AtomsButton
        :appearance="['outline']"
        :to="{ id: 'page_donate' }"
        text="Donate"
        class="site-header-donate"
      />
      <AtomsHamburger
        class="site-header-burger"
        :active="menu.active"
        @update="menu.set"
      />
    </div>
    <AtomsButton
      v-if="preview"
      plain
      class="site-header-refresh"
      text="Refresh Content"
      @click="refreshNuxtData()"
    />
  </BlocksSection>
</template>

<script lang="ts" setup>
import type { NavItem } from '~~/types'
import Logo from '~/assets/icons/logo.svg?skipsvgo'

const { affix, menu } = useCzn()

const { theme } = usePagesBody()
const { query } = useRoute()

const preview = computed(() => Object.keys(query).includes('preview'))

const { button } = useDghGroq()

const { data: items } = useSanityQuery<NavItem[]>(
  groq`*[_type == "siteSettings"][0].social[] {
    icon,
    ${button()},
    title,
    text
  }`
)
</script>

<style lang="scss">
.site-header.section {
  position: fixed;
  inset: 0 0 unset;
  width: 100%;
  max-width: 100vw;
  height: 9rem;
  padding: 0 czn.$lg;
  max-height: 9rem;
  margin-bottom: 0;
  z-index: 900;
  transition: czn.transition(var(--transition), (max-height));
  overflow: hidden;
  font-size: 1.2rem;
  mix-blend-mode: unset;
  pointer-events: none;

  @include media('<tablet') {
    height: 7rem;
  }

  &::before {
    position: absolute;
    inset: 0;
    content: '';
    background: var(--background);
    opacity: 0;
    transform: translateY(-10rem);
    transition: czn.transition(var(--transition), (transform, opacity));
  }

  &.affix {
    max-height: 5rem;

    @include media('<tablet') {
      max-height: 4rem;
    }

    &::before {
      opacity: 1;
      transform: none;
    }
  }

  .container {
    position: relative;
    display: grid !important;
    justify-content: space-between;
    align-content: center;
    height: 100%;
    max-height: 100%;
    grid-template-columns: repeat(3, 1fr);
    max-width: unset;

    > * {
      animation: appearY 1s 0.5s backwards;
      pointer-events: all;

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
        animation-delay: #{1.75 + ($i * 0.25)}s;
        }
      }
    }
  }
  &:not(.affix):not(.theme-alt) .site-header-right > * {
    background: var(--background);
  }

  .site-header {
    &-nav-list,
    &-right .button {
      @include media('<tablet') {
        display: none;
      }
    }
    &-donate {
      @include media('<desktop') {
        font-size: 1rem;
        padding: czn.$rg czn.$lg;
      }
    }
    &-nav-link {
      padding: czn.$rg;
      font-size: 1.6rem;

      .nuxt-button-text {
        margin-bottom: -0.15em;
        font-weight: 400;
        font-size: 1.2rem;
      }
    }
    &-right {
      display: flex;
      grid-column: 3;
      justify-self: flex-end;
    }
    &-burger {
      margin-left: 2vw;
    }
    &-logo {
      position: absolute;
      top: czn.$sm;
      left: 0;
      right: 0;
      justify-self: center;
      margin: 0 auto;
      width: 8.5rem;
      height: 8.5rem;
      transition: czn.transition(var(--transition), (max-height));

      svg {
        width: 100%;
        height: 100%;
        transform-origin: 0% czn.$xl;
        transition: czn.transition(var(--transition), (transform));

        path {
          transition: czn.transition(var(--transition), (transform, opacity));
        }

        &.minimal {
          transform: scale(1.1);
          .est,
          .grahams,
          .homes,
          .dr,
          .kalimpong-uk {
            transform: translateX(czn.$md);
            opacity: 0;
            pointer-events: none;
          }
        }
      }

      @include media('<tablet') {
        margin-left: unset;
        width: 6rem;
        height: 6rem;

        svg {
          transform-origin: 0% czn.$md;
        }
      }
    }
  }

  &.preview {
    > .container {
      display: flex;
      flex-flow: row;
    }

    .site-header-logo {
      position: relative;
      align-self: flex-start;
      justify-self: flex-start;
      width: auto;
      margin: 0.25rem 0 0;
      pointer-events: none;
    }

    .site-header-refresh {
      justify-self: center;
      width: auto;
      margin: 0 auto;
    }
  }
}
</style>
