<template>
  <button
    class="csn-hamburger"
    :class="{ 'csn-active': active, text }"
    :title="active ? 'Close menu' : 'Open Menu'"
    @click="toggleActive()"
  >
    <!-- <span class="csn-hamburger-text">Menu</span> -->
    <span class="csn-hamburger-icon">
      <span
        v-for="span in ['a', 'b', 'c']"
        :key="span"
        class="csn-hamburger-line"
        :class="`csn-line-${span}`"
        v-text="text && span === 'b' ? 'Menu' : ''"
      />
    </span>
  </button>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    active: {
      type: Boolean,
      default: false,
      required: true,
    },
    text: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggleActive() {
      this.$emit('update', !this.active)
    },
  },
}
</script>

<style lang="scss">

.csn-hamburger {
  --transition: 0.3s ease;
  --color: black;
  $size: 2em;
  position: relative;
  z-index: 100;
  border-radius: var(--br-items-lg);

  font-size: 1em;
  align-items: center;
  display: flex;
  width: auto;
  margin: 0;
  padding: 0.25em;

  @include media('<tablet') {
    font-size: 1.4em;
  }

  &-icon {
    position: relative;

    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: space-evenly;
    width: $size;
    height: $size;
    margin: 0;
    padding: 0;

    transition: transform var(--transition), opacity var(--transition),
      visibility var(--transition);
  }

  &-text {
    margin-right: czn.$md;
    color: var(--body);

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &-line {
    height: 0.14rem;

    background: var(--emph);

    border-radius: czn.$xs;

    transition: transform var(--transition), color var(--transition), opacity var(--transition);

    &.csn-line {
      &-a {
        transform-origin: 0% 50%;
      }
      &-b {
        transform-origin: 25% 50%;
      }
      &-c {
        transform-origin: 0% 50%;
      }
    }
  }

  &:hover,
  &:focus {
    .csn-line {
      &-a {
        transform: translateX(12.5%) scaleX(0.75);
      }
      &-b {
        transform: translateX(12.5%) scaleX(0.5);
      }
      &-c {
        transform: translateX(12.5%) scaleX(0.75);
      }
    }
  }

  &.csn-active {
    .csn-line {
      &-a {
        transform: translateX(12.5%) rotate(45deg) scaleX(1.1);
      }
      &-b {
        transform: translateX(12.5%) scaleX(0);
      }
      &-c {
        transform: translateX(12.5%) rotate(-45deg) scaleX(1.1);
      }
    }
  }

  &.text {
    .csn-line-b {
      display: flex;
      height: unset;
      justify-content: center;
      margin-top: 1px;
      margin-bottom: -1px;
      background: none;
      transform-origin: 50% 50%;
      color: var(--body);
    }
    &:hover,
    &:focus {
      .csn-line-b {
        transform: scale(1.05);
      }
    }
    &.csn-active {
      .csn-line-b {
        transform: scale(0.8);
        opacity: 0;
      }
    }
  }
}
</style>
