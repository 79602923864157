<template>
  <transition name="site-modal" :duration="300">
    <aside v-if="modal.active" class="site-modal theme-dark" :style="style">
      <template v-if="data">
        <Component
          :is="data.to ? AtomsButton : 'span'"
          :to="data.to"
          class="site-modal-button"
          plain
          @click="modal.close()"
        >
          <AtomsImage
            v-if="data.image"
            :image="data.image"
            class="site-modal-image"
            :zoom="false"
          />
          <div class="site-modal-content">
            <AtomsHeading
              element="h4"
              :text="data.title"
              class="site-modal-heading"
            />
            <p class="site-modal-body" v-text="data.description" />
            <span class="card-button site-modal-action">
              Read more
              <AtomsIcon name="arrow-long-right" />
            </span>
          </div>
        </Component>
        <AtomsButton
          class="site-modal-close"
          plain
          text="✖"
          :class="{ active: modal.progress > 0 }"
          @click="modal.close()"
        >
          <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            class="site-modal-close-border"
          >
            <circle cx="50%" cy="50%" r="45%" :style="{ strokeDashoffset }" />
          </svg>
        </AtomsButton>
      </template>
    </aside>
  </transition>
</template>

<script lang="ts" setup>
// const { modal } = useModal()
// const { supportsLocalStorage } = useLocalStorage()
import type { ModalContent } from '~~/types'

const { y } = useWindowScrollPosition()

const { button, image } = useDghGroq()

const modal = reactive({
  enabled: ref(false),
  active: computed(
    () => !!modal.enabled && !!data.value?.enabled && !!data.value?.title
  ),
  modifier: ref(1),
  watchScrolling: ref(false),
  progress: computed<number>(() => {
    if (modal.watchScrolling) {
      const progress = y.value / (1400 * modal.modifier)
      if (progress > 1) {
        modal.modifier = 0.25
        return 1
      } else return progress
    } else {
      if (y.value <= 100) modal.modifier = 1
      else if (y.value > 100 && y.value < 700) modal.watchScrolling = true
      else modal.modifier = 0.25

      return 0
    }
  }),
  close() {
    modal.enabled = false
    // if (process.client && supportsLocalStorage.value)
    //   localStorage.setItem('modalClosed', 'true')
  },
})

onMounted(() => {
  // if (
  //   process.client &&
  //   supportsLocalStorage.value &&
  //   localStorage.getItem('modalClosed') === 'true'
  // )
  //   modal.enabled = false
  // else 
  modal.enabled = true
})

const { data } = useSanityQuery<ModalContent>(
  groq`*[_type == "siteSettings"][0].modal {
    enabled,
    ${button(true, 'description,\n"image": images[0]' + image + ',')},
    defined(description) => {description},
    defined(image) => {image${image}},
  }`,
  {},
  {
    transform: (
      data: Omit<ModalContent, 'title'> & { text?: string }
    ): ModalContent => {
      data['title'] = data.text
      delete data.text
      return data
    },
  }
)

const AtomsButton = resolveComponent('AtomsButton')

const style = computed(() => {
  const math = Math.cos(Math.PI * modal.progress)

  const isDesktop = window.innerWidth > 1024

  return {
    transform: isDesktop
      ? `translateX(${math * 5 -5}vw)`
      : `translateY(${math * -2 + 2}rem)`,
    opacity: (math + 1) / 2,
    pointerEvents: modal.progress > 0.45 ? 'none' : 'all',
  }
})
const strokeDashoffset = computed(() => 500 * (1 - modal.progress))
</script>

<style lang="scss">
.site-modal {
  position: fixed;

  inset: auto czn.$md czn.$lg;
  max-width: 60rem;
  margin: 0 auto;
  z-index: 900;

  transition: czn.transition(0.2s ease-out, (opacity, transform));

  &-enter-from,
  &-leave-to {
    transform: translateY(10rem) !important;
    opacity: 0 !important;

    @include media('>desktop') {
      transform: translateX(-100vw) !important;
    }
  }
  &-enter-active,
  &-leave-active {
    transition: czn.transition(1s ease-out, (transform, opacity)) !important;
  }
  &-enter-active {
    transition-delay: 1s !important;
  }

  @include media('>desktop') {
    right: 25vw;
  }

  .site-modal {
    &-button {
      display: flex;
      width: 100%;
      height: 100%;
      background: var(--background);
      min-height: 6rem;
      align-items: unset;
      padding: czn.$md;

      border-radius: var(--br-items-sm);

      @include media('<tablet') {
        padding: czn.$rg;
      }

      &:hover,
      &:focus,
      &:active {
        .site-modal-action {
          transform: translateX(0.5em);
        }
      }
    }

    &-close {
      position: absolute;
      inset: -0.5em -0.5em auto auto;
      border-radius: 100%;
      z-index: 10;
      width: 1.6em;
      height: 1.6em;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.65em;
      color: var(--background);
      background: var(--emph);
      padding-top: 0.3em;
      transition: czn.transition(var(--transition), (transform));

      &.active {
        transform: scale(1.2);
      }

      &:hover,
      &:focus,
      &:active {
        transform: scale(1.2) rotate(360deg);
      }

      &-border {
        position: absolute;
        inset: -2px;
        z-index: -1;
        transform: rotate(-90deg);

        circle {
          fill: none;
          stroke-dasharray: 500;
          stroke-dashoffset: 500;
          stroke-width: 10%;
          stroke: var(--primary);
        }
      }
    }

    &-image {
      width: 12rem;
      max-width: 25%;
      max-height: 6rem;
      // margin: 0 - czn.$rg czn.$lg;
      margin-left: 0;
      border-radius: var(--br-items-md);

      @include media('<tablet') {
        max-height: unset;
        border-radius: var(--br-items-rg);
      }
    }

    &-content {
      align-self: center;
      font-size: 1rem;
      width: 100%;
      padding: czn.$rg 0;
      padding-left: min(czn.$lg, 5vw);
    }

    &-heading {
      margin-bottom: 0.3em;

      em {
        font-style: italic;
      }
    }

    &-body {
      font-weight: medium;
    }

    &-action {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      padding-right: 1em;
      margin-top: 0.2em;
      transition: czn.transition(var(--transition), (transform));

      .icon {
        margin-left: 0.4em;
      }
    }
  }
}
</style>
