<template>
  <component
    :is="element"
    :id="localId"
    class="heading"
    :style="!text && delay && { animationDelay: delay + 's' }"
    :class="[`heading-${element.replace('h', '')}`, { plain: !text }]"
  >
    <slot />
    <template v-if="text">
      <em
        v-if="!Array.isArray(text) && em"
        class="heading-text heading-main"
        :style="{ animationDelay: delay + 's' }"
        v-text="text"
      />
      <span
        v-else-if="!Array.isArray(text)"
        class="heading-text heading-main"
        :style="{ animationDelay: delay + 's' }"
        v-text="text"
      />
      <component
        :is="span.style === 'secondary' ? 'em' : 'span'"
        v-for="(span, index) in text"
        v-else
        :key="index"
        class="heading-text"
        :style="{ animationDelay: delay + index * 0.25 + 's' }"
        :class="`heading-${span.style}`"
      ><slot />{{ span.children && span.children[0].text }}</component>
    </template>
  </component>
</template>

<script lang="ts" setup>
const props = defineProps({
  id: {
    type: String,
    default: undefined,
  },
  element: {
    type: String,
    default: 'h2',
  },
  em: {
    type: Boolean,
    default: true,
  },
  children: {
    type: Array as () => any[],
    default: () => [],
  },
  text: [Array, String],
  delay: { type: Number, default: 0 },
})

const localId = computed(() => props.id || props.text
  ? Array.isArray(props.text) ? slugify(props.text.map(child => child.text).join()) : slugify(props.text)
  : props.children && props.children[0]?.text && slugify(props.children[0].text))
</script>

<style lang="scss">
.section.intersected .heading-text {
  animation-play-state: running !important;
}

.heading {
  position: relative;
  display: flex;
  flex-flow: column;
  line-height: 1;
  align-self: flex-start;

  .heading-main,
  .heading-normal {
    font-style: normal;
    font-weight: 600;
  }
  em,
  .heading-secondary {
    margin-left: 1em;
    font-size: 0.8em;
    font-weight: 300;

    &:first-child:last-child {
      margin-left: 0;
    }
  }
}

.heading:first-child,
.image-background:first-child + .heading {
  margin-top: 0;
}
</style>
